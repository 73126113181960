import React from 'react';
import { Panel, Button, Input, Div, FixedLayout, Progress, Spinner, Cell, Avatar } from '@vkontakte/vkui';
import Header from '../components/Header/Header';
import TextBlock from '../components/Text/Text';
//хелперы
import app from '../func/app';
import config from '../config';
import { getAddScope, getAllowMessagesFromGroup } from '../func/vk';
import { getHystoryDialog } from '../func/back';
import { disableDialog, sendMessage, saveDialog, initCouples } from '../func/wss';
import { findObjectById, declOfNum } from '../func/utils';
import { upload } from '../func/uploadPhoto';
import PhotoGalery from '../components/PhotoGalery/PhotoGalery';
import Icon28Send from '@vkontakte/icons/dist/28/send';
import Icon28AttachOutline from '@vkontakte/icons/dist/28/attach_outline';
import { animateScroll as scroll } from 'react-scroll'

class Chat extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			blockSendMessage: false,
			history: false,
			allowedMessages: false,
		};
	}

	componentDidMount() {
		const { isDialog, partner } = this.props
		const offset 	= 0
		const count 	= 10
		const ts 		= Math.floor(Date.now() / 1000) //текущий таймстамп

		if (isDialog) {
			/*
				Возобновляем диалог
			*/
			initCouples(partner.uid, (response) => {
				
				/*
					Получаем историю
				*/
				getHystoryDialog(offset, count, ts, partner.uid, (answer) => {
					const { history, isSaved } = answer
					app.setState({ chat: history, isSaved:isSaved, hystoryTs: ts, hystoryCount: count, hystoryOffset: offset }, () => {
						this.setState({ history: true })
					})
				})

			})
		} else {
			getHystoryDialog(offset, count, ts, partner.uid, (answer) => {
				const { history, isSaved } = answer
				app.setState({ chat: history, isSaved:isSaved, hystoryTs: ts, hystoryCount: count, hystoryOffset: offset }, () => {
					this.setState({ history: true })
				})
			})
		}

		window.onkeypress = (e) => {
			 if (e.keyCode === 13) {
			 	this.onSendMessage()
			 }
		}
	}

	componentWillUnmount() {
		app.setState((state) => ({ isEpic: true }))
		const { partner } = this.props
		disableDialog(partner.uid, (response) => {})
	}

	onChangeMessage = (e) => {
		const { value } = e.currentTarget;
		app.setState({ message: value });
	}

	onSendMessage = () => {
		const { message, attachment, partner } = this.props
		if ( (!this.state.blockSendMessage && message.length > 0) || (attachment.photos.length > 0) ) {
			this.setState({
				blockSendMessage: true,
			}, () => {
				sendMessage(partner.uid, message, attachment, (response) => {
					const myMessage = response.message
					app.setState((state) => ({
						chat: [...state.chat, myMessage],
						message: "",
						attachment: { 
							photos: [],
						},
					}))
					this.setState({ blockSendMessage: false })
					//скролим вниз
					scroll.scrollToBottom({
						duration: 0,
						delay: 0,
					})
				})
			})
		}
	}

	onSaveDialog = () => {
		const { partner } = this.props
		const { group_id } = config
		getAllowMessagesFromGroup(group_id, (allow) => {
			saveDialog(partner.uid, (response) => {
				const myMessage = response.message
				app.setState((state) => ({
					chat: [...state.chat, myMessage],
					message: "",
					profiles: [partner, ...state.profiles], 
					isSaved: true 
				}), () => {
					scroll.scrollToBottom({
						duration: 0,
						delay: 0,
					})
				})
			})
		}, (disallow) => {
			//отказался
		})
	}

	getAllowMessages = () => {
		const { group_id } = config
		getAllowMessagesFromGroup(group_id, (allow) => {
			this.setState({
				allowedMessages: true,
			})
		}, (disallow) => {
			//отказался
		})
	}

	renderChat = () => {
		const { allowedMessages } = this.state
		const { chat, profile } = this.props
		return chat.map(({text, attachment, time, my, system, saved}, i) => {
			const { photos } = attachment
			return (
				//тут нужно реализовать блок сообщений
				<Div className={(system) ? `flex_center pb-0` : (my) ? `flex_end pb-0` : `flex_start pb-0`} key={i}>
					{
						(system) ?
							<div className="__ChatSystemMessage">
								<center>
									{text}
									{
										(saved && !my && !allowedMessages) ? 
											<div>
												<div> Разрешите присылать Вам уведомления, чтобы получать сообщения, когда Вы офлайн </div>
												<div className="pt-10"> <Button onClick={this.getAllowMessages} level="outline">Разрешить</Button> </div>
											</div>
										: null
									}
								</center> 
							</div>
						: 
							(photos.length > 0) ? //есть фотка
								<div className="__ChatMessage">
									<div> 
										{text} 
										<PhotoGalery itsVip={profile.itsVip} photos={photos}/>
									</div>
								</div>
							: 
								<div className="__ChatMessage">
									<TextBlock itsVip={profile.itsVip} show={false} showMoreButton={true} length={700}>{text}</TextBlock>
								</div>
					}
				</Div>
			)
		})
	}

	

	/*
		Загрузка фото на сервер ВК
	*/
	onChangePhoto = (e) => {
		const { userScope } = this.props
		const files = [...e.target.files]
		e.target.value = null; //скидываем value чтобы загружать одну и ту же офтографию подряд
			if (files.length > 0) {
				if (userScope.indexOf('photos') !== -1) {
					//есть нужные права
					upload(files[0], (fileInVk) => {
						app.setState((state) => ({
							attachment: {
								...state.attachment,
								photos: [...fileInVk],
							},
							progress: 0
						}), this.onSendMessage)
						
					})
				} else {
					//нет нужных прав, расширяем права
					getAddScope(["photos"], userScope, (token) => {
						if (token.scope.split(',').indexOf('photos') !== -1) {
							config.set({access_token: token.access_token}, () => {
								app.setState({
									userScope: token.scope.split(','),
								}, () => {
									//тут можно приступить к загрузке фото на сервер
									upload(files[0], (fileInVk) => {
										app.setState((state) => ({
											attachment: {
												...state.attachment,
												photos: [...fileInVk],
											},
											progress: 0
										}), this.onSendMessage)
										
									})
								})
							})
						}
					}, (error) => {
						console.log(error)
					})
				}
			}
	

	}

	render() {
		const { history } = this.state
		const { id, message, profile, partner, progress, apiUsersData, isDialog, navigator, isSaved, apiCitiesData } = this.props
		const { uid } = partner
		const user = findObjectById(apiUsersData, +uid)
		const city = findObjectById(apiCitiesData, partner.city)
		return (
			<Panel theme="white" id={id}>
				<Header panelBack={ navigator.goPanelBack } name="Чат"/>

				<Cell 
					before={
						<Avatar className={(!profile.itsVip) ? (partner.itsVip) ? `thisHidden thisVip` : `thisHidden` : (partner.itsVip) ? `thisVip` : ``} src={user.photo_50} size={48} />
					}
					description={
						<div>
							{partner.age} {declOfNum(partner.age, ['год', 'года', 'лет'])}
							{ (city && profile.itsVip) ? `, ${city.title}` : null }
						</div>
					}
				>
					{user.first_name}
				</Cell>

				<div className="__ChatBlock">
					{ (history) ? this.renderChat() : <Spinner size="regular" style={{ marginTop: 20 }} /> }
				</div>

				<FixedLayout vertical="bottom">
					{
						(!isDialog) ?
							<div className="flex_center __ChatControlBlock">
								<Button className="mr-5" level="outline" onClick={navigator.goPanelBack}>Следующий</Button>

								<Button disabled={isSaved} level="outline" onClick={this.onSaveDialog}>Сохранить диалог</Button>
							</div>
						: 
							null
					}
					
					{/* тут загрузка */}
					<div className="__ChatProgressBlock">
						<Progress value={progress} />
					</div>

					<div className="flex_start __ChatMessageBlock">
						<label htmlFor="photoFile">
							<Icon28AttachOutline fill="var(--tabbar_inactive_icon)" className="pr-5 pl-5"/>
						</label>
						<input type="file" id="photoFile" accept="image/*" onChange={this.onChangePhoto} className="__ChatHiddenInput"/>

						<Input
							className="__ChatCustomInput"
							type="text"
							value={message}
							onChange={this.onChangeMessage}
							onClick={() => {
								scroll.scrollToBottom({
									duration: 0,
									delay: 500,
								})
							}}
							placeholder="Сообщение"
						/>

						<Icon28Send fill="var(--tabbar_inactive_icon)" onClick={this.onSendMessage} className="pr-5 pl-5"/>
					</div>

				</FixedLayout>


			</Panel>
		)
	}
}

export default Chat;