import React from 'react';
import { Panel, Div, Cell, Switch, Group } from '@vkontakte/vkui';
import Header from '../../components/Header/Header';

//хелперы
import config from '../../config';
import app from '../../func/app';
import { setIsNotify } from '../../func/back';
import { getAllowMessagesFromGroup } from '../../func/vk';

class Notify extends React.Component {

	onChange = (e) => {
		const { checked } = e.currentTarget
		if (checked) {
			
			const { group_id } = config
			getAllowMessagesFromGroup(group_id, (allow) => {
				app.setState((state) => ({
					profile: {
						...state.profile,
						isNotify: checked
					}
				}), () => {
					setIsNotify(checked)
				})
			})

		} else {
			app.setState((state) => ({
				profile: {
					...state.profile,
					isNotify: checked
				}
			}), () => {
				setIsNotify(checked)
			})
		}
	}

	render() {
		const { id, snackbar, navigator, profile } = this.props
		const { isNotify } = profile
		return (
			<Panel theme="white" id={id}>
				<Header panelBack={ navigator.goPanelBack } name="Уведомления"/>
				{
					/* Уведомления */
					snackbar 
				}
				<Div className="flex_center">
					Уведомления
				</Div>
				<Div className="flex_start pt-0">
					Сохраняйте диалоги с пользователями, а мы оповестим Ваc, если они Вам напишут. Для этого включите уведомления.
				</Div>
				<Group>
					<Cell asideContent={<Switch onChange={this.onChange} defaultChecked={isNotify} />}>
						Уведомления
					</Cell>
				</Group>

			</Panel>
		)
	}
}

export default Notify;