import React from 'react';
import { Panel, Div, Button, Spinner, Input, FormLayout, RangeSlider, SelectMimicry } from '@vkontakte/vkui';
import Header from '../../components/Header/Header';
import { showInfoMessage, showErrorMessage } from '../../func/alert';
import { setProfile } from '../../func/back';
import SexChange from '../../components/SexChange/SexChange';
import { findObjectById } from '../../func/utils';
import { updateProfile } from '../../func/wss';
import app from '../../func/app';

class Profile extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			disabled: false,
			wait: false,
		};
	}

	getCountry = () => {
		const { apiCountriesData } = this.props
		const { profile } = this.props
		const country = findObjectById(apiCountriesData, profile.country)
		if (country) {
			return country
		} else {
			return {id: 0, title: " "}
		}
	}

	getCity = () => {
		const { apiCitiesData } = this.props
		const { profile } = this.props
		const city = findObjectById(apiCitiesData, profile.city)
		if (city) {
			return city
		} else {
			return {id: 0, title: " "}
		}
	}

	onChangeSex = (sex) => {
		app.setState((state) => ({
			profile: {
				...state.profile,
				sex: sex 
			}
		}));
	}

	onChangeFindSex = (sex) => {
		app.setState((state) => ({
			profile: {
				...state.profile,
				findSex: sex 
			}
		}));
	}

	onChange = (e) => {
		const { name, value } = e.currentTarget
		app.setState((state) => ({
			profile: {
				...state.profile,
				[name]: value 
			}
		}));
	}

	onChangeFindAge = (e) => {
		app.setState((state) => ({
			profile: {
				...state.profile,
				findAgeMin: e[0],
				findAgeMax: e[1],
			}
		}));
	}

	saveChanged = () => {
		const { profile } = this.props
		const { age, city, country, sex, findSex, findAgeMin, findAgeMax } = profile
		if (+age < 16 || +age > 60) {
			showErrorMessage('Ошибка!','Возраст должен быть выше 16')
		} else {
			this.setState({
				disabled: true
			}, () => {
				setProfile(age, city, country, sex, findSex, findAgeMin, findAgeMax, () => {
					showInfoMessage('Отлично!','Все настройки сохранены')
					updateProfile(age, city, country, sex, findSex, findAgeMin, findAgeMax);
					this.setState({ disabled: false })
				})
			})
		}
	}

	render() {
		const { wait, disabled } = this.state
		const { id, navigator, snackbar, profile } = this.props
		return (
			<Panel theme="white" id={id}>
				<Header panelBack={ navigator.goPanelBack } name="Уведомления"/>
				{
					/* Уведомления */
					snackbar 
				}
				{
					(wait) ?
						<Spinner size="regular" style={{ marginTop: 20 }} />
					: 
						<div>
							<div className="flex_center ___welcomeTitle">Кто вы?</div>
								<Div>
									<SexChange
										onChange={this.onChangeSex}
										sex={profile.sex || 1}
									/>
								</Div>
								<FormLayout>
									<Input 
										top="Введите ваш возраст" 
										type="number"
										pattern="\d*"
										min="15" 
										max="60"
										value={String(profile.age)}
										name="age"
										status={+profile.age >= 15 && +profile.age <= 60 ? 'valid' : 'error'}
										onChange={this.onChange}
									/>

									<SelectMimicry
										top="Выберите страну"
										placeholder="Не выбрана"
										onClick={() => {navigator.goPanel('ChangeCountry')}}
									>
										{this.getCountry().title}
									</SelectMimicry>

									<SelectMimicry
										top="Выберите город"
										placeholder="Не выбран"
										onClick={(this.getCountry().id !== 0) ? () => { navigator.goPanel('ChangeCity')} : () => { showErrorMessage('Ошибка!','Выберите сначала страну') }}
										disabled={(this.getCountry().id === 0) ? true : false}
									>
										{this.getCity().title}
									</SelectMimicry>

								</FormLayout>
								<div className="flex_center ___welcomeTitle pt-20">Кого ищем?</div>

								<Div>
									<SexChange
										onChange={this.onChangeFindSex}
										sex={profile.findSex || 1}
									/>
								</Div>
								<FormLayout>
									<RangeSlider
										top={`От ${profile.findAgeMin} до ${profile.findAgeMax}`}
										min={15}
										max={60}
										step={1}
										onChange={this.onChangeFindAge}
										value={[profile.findAgeMin, profile.findAgeMax] || [18,21]}
									/>
								</FormLayout>
								<Div className="flex_center">
									<Button 
										disabled={disabled}
										size='l'
										onClick={this.saveChanged}
									>
										Сохранить настройки
									</Button>
								</Div>
							</div>
				}
			</Panel>
		)
	}
}

export default Profile;