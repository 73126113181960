//Этот файл сгенерирован автоматически
import { apiRequest } from '../../vkApiRequest';


//Returns list of chairs on a specified faculty.
//user,service
export function databaseGetChairs(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("database.getChairs",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Returns a list of cities.
//user
export function databaseGetCities(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("database.getCities",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Returns information about cities by their IDs.
//user,service
export function databaseGetCitiesById(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("database.getCitiesById",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Returns a list of countries.
//user
export function databaseGetCountries(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("database.getCountries",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Returns information about countries by their IDs.
//user,service
export function databaseGetCountriesById(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("database.getCountriesById",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Returns a list of faculties (i.e., university departments).
//user,service
export function databaseGetFaculties(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("database.getFaculties",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Get metro stations by city
//user,service
export function databaseGetMetroStations(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("database.getMetroStations",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Get metro station by his id
//user,service
export function databaseGetMetroStationsById(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("database.getMetroStationsById",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Returns a list of regions.
//user,service
export function databaseGetRegions(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("database.getRegions",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Returns a list of school classes specified for the country.
//user,service
export function databaseGetSchoolClasses(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("database.getSchoolClasses",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Returns a list of schools.
//user,service
export function databaseGetSchools(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("database.getSchools",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}

//Returns a list of higher education institutions.
//user,service
export function databaseGetUniversities(access_token, params = {}, successCallback = () => {}, errorCallback = () => {}) {
	apiRequest("database.getUniversities",{ ...params
	}, access_token, (response) => {
		successCallback(response)
	},(error) => {
		errorCallback(error)
	})
}
