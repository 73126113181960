import React from 'react';
import { ScreenSpinner, ConfigProvider } from '@vkontakte/vkui';
import '@vkontakte/vkui/dist/vkui.css';

import Stack from './components/Navigator/Stack';
import Page from './components/Navigator/Page';

//хелперы
import app from './func/app';

import { go } from './func/init';
import { devLog } from './func/utils';

//панель ошибок wss
import WssErrors from './panels/WssErrors/WssErrors';

//стартовые панели
import Twister from './panels/Twister';
import Dialog from './panels/Dialog';
import Settings from './panels/Settings';
import Chat from './panels/Chat';

/* Настройки */
import Notify from './panels/Settings/Notify'; //Настройки уведомлений
import Profile from './panels/Settings/Profile'; //Настройки поиска
import ChangeCountry from './panels/Settings/ChangeCountry'; //Выбор страны
import ChangeCity from './panels/Settings/ChangeCity'; //Выбор города
import BuyVip from './panels/Settings/BuyVip'; //Покупка режима VIP


//Welcome Panel
import Welcome from './panels/Welcome/Welcome';


//иконки для эпик меню
import Icon24Locate from '@vkontakte/icons/dist/24/locate';
import Icon28Messages from '@vkontakte/icons/dist/28/messages';
import Icon24Settings from '@vkontakte/icons/dist/24/settings';

import { animateScroll as scroll } from 'react-scroll'

class App extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			scheme: "client_light", //тема оформления client_light, client_dark, bright_light, space_gray
			header: false, //показывает или скрывает хедер для отображения при кастомном хедере на десктопе
			userScope: [], //права пользователя
			isStartInGroup: false, //сервис запущен из группы
			isAdminInGroup: false, //пользователь администратор в сообществе
			isWebView: false, //isWebView
			goStartPanel: false, //показывать стартовый экран
			isLoad: false, //загрузка приложения
			wssCloseCode: false, //если включен wss в config, то определяет какую ошибку показать
			popout: null, //всплывающие окна
			apiUsersData: [], //информация о пользователях из апи
			apiGroupsData: [], //информация о группах из апи
			apiCitiesData: [], 
			apiCountriesData: [], 

			isSaved: false, //сохранёнли этот диалог

			profiles: [], //профили из диалогов
			profile: false, //профиль пользователя
			isEpic: true, //показывать ли нижнее меню

			answer: false, //найден собеседник, вопрос о начале чата
			partnerSayYes: false, // партнёр согласился на диалог
			partnerSayNo: false, // партнёр отказался от дилога
			partner: {}, //анкета партнёра
			twist: false, // идёт поиск 
			//чат
			message: "", //инпут сообщения
			attachment: { //прикрепления
				photos: [],
			},
			progress: 0, //линия загрузки фото
			chat: [], //чат

			dialogTs: 0, //таймстамп для диалогов
			dialogCount: 10,
			dialogOffset: 0,
			countDialog: 0, //общее количество сохранённых диалогов

			hystoryTs: 0, //таймстамп для истории соообщений
			hystoryCount: 10,
			hystoryOffset: 0,

			snackbar: null, //уведомления для всех окон кроме чата и диалога
			heartBeatAnimate: false, //анимация сердца
		};
	}

	componentDidMount() {
		app.setState = (state, callback) => { this.setState(state, callback) }
		go()
	}

	onTransition = (e) => {
		const { to } = e
		if (to === 'Chat') {
			scroll.scrollToBottom({
				duration: 0,
				delay: 100,
			})	
		}
	}

	render() {
		devLog(this.state)
		let { isLoad, popout, userScope, header, scheme, goStartPanel, isWebView, wssCloseCode, profile, isEpic, apiUsersData, answer, partnerSayYes, partnerSayNo, 
		partner, twist, message, chat, attachment, progress, profiles, isSaved, countDialog, dialogCount, dialogOffset, dialogTs, snackbar, apiCountriesData, 
		apiCitiesData, heartBeatAnimate } = this.state

		if (!isLoad) { //Показываем спиннер или если был включен wss ошибку wss
			if (wssCloseCode && wssCloseCode !== 4003) { 
				return <WssErrors id="WssErrors" wssCloseCode={wssCloseCode}/>
			} else {
				return <ScreenSpinner/>
			}
		}

		if (isLoad) { //сервис загружен
			return (
				<ConfigProvider scheme={scheme} isWebView={isWebView} webviewType="vkapps">

					<Stack id="main" activePage="chatStack" isEpic={(isEpic) ? (!goStartPanel) : false}>
						<Page onTransition={this.onTransition} id="chatStack" activePanel="Twister" header={(!goStartPanel) ? header : true} popout={popout} title="Чат" icon={<Icon24Locate/>}>
							<Welcome 
								id={(goStartPanel) ? `Twister` : `Welcome`}
								userScope={userScope}
								profile={profile}
							/>
							<Twister 
								id={(!goStartPanel) ? `Twister` : `Welcome`}
								profile={profile}
								apiUsersData={apiUsersData}
								apiCitiesData={apiCitiesData}
								answer={answer}
								partnerSayYes={partnerSayYes}
								partnerSayNo={partnerSayNo}
								partner={partner}
								twist={twist}
								snackbar={snackbar}
								heartBeatAnimate={heartBeatAnimate}
							/>
							<Chat 
								id="Chat"
								profile={profile}
								apiUsersData={apiUsersData}
								apiCitiesData={apiCitiesData}
								partner={partner}
								message={message}
								attachment={attachment}
								chat={chat}
								progress={progress}
								userScope={userScope}
								isDialog={false}
								isSaved={isSaved}
							/>
						</Page>

						<Page onTransition={this.onTransition} id="dialogStack" activePanel="Dialog" header={header} popout={popout} title="Диалоги" icon={<Icon28Messages width="24"/>}>
							<Dialog 
								id="Dialog"
								apiUsersData={apiUsersData}
								apiCitiesData={apiCitiesData}
								apiCountriesData={apiCountriesData}
								profiles={profiles}
								profile={profile}
								
								countDialog={countDialog}
								dialogCount={dialogCount}
								dialogOffset={dialogOffset}
								dialogTs={dialogTs}
								snackbar={snackbar}
							/>

							<Chat 
								id="Chat"
								profile={profile}
								apiUsersData={apiUsersData}
								apiCitiesData={apiCitiesData}
								partner={partner}
								message={message}
								attachment={attachment}
								chat={chat}
								progress={progress}
								userScope={userScope}
								isDialog={true}
								isSaved={isSaved}
							/>
						</Page>

						<Page id="settingsStack" activePanel="Settings" header={header} popout={popout} title="Настройки" icon={<Icon24Settings/>}>
							<Settings 
								id="Settings"
								snackbar={snackbar}
							/>

							<Notify
								id="Notify"
								snackbar={snackbar}
								profile={profile}
							/>

							<Profile
								id="Profile"
								snackbar={snackbar}
								profile={profile}
								apiCitiesData={apiCitiesData}
								apiCountriesData={apiCountriesData}
							/>
							<ChangeCountry
								id="ChangeCountry"
								snackbar={snackbar}
								profile={profile}
							/>
							<ChangeCity
								id="ChangeCity"
								snackbar={snackbar}
								profile={profile}
							/>
							
							<BuyVip
								id="BuyVip"
								snackbar={snackbar}
								profile={profile}
							/>
						</Page>
					</Stack>
				</ConfigProvider>
			);
		}
	}
}

export default App;