import React from 'react';
import { Panel, Placeholder, Button } from '@vkontakte/vkui';

import './style.css';
import Icon56ErrorOutline from '@vkontakte/icons/dist/56/error_outline';

class WssErrors extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	renderError = (wssCloseCode) => {
		switch (wssCloseCode) {
			case 4000:
				return (
					<Placeholder
						icon={<Icon56ErrorOutline/>}
						header="Ошибка!"
						action={<Button onClick={() => { window.location.reload() }}> Перезагрузить страницу</Button>}
						stretched={true}
					>
						Похоже Вы открыли больше одной вкладки или зашли с другого устройства, перезагрузите страницу.
					</Placeholder>
				)
			case 4001:
				return (
					<Placeholder
						icon={<Icon56ErrorOutline/>}
						header="Ошибка!"
						action={<Button onClick={() => { window.location.reload() }}> Перезагрузить страницу</Button>}
						stretched={true}
					>
						Истекло время ожидания регистрации.
					</Placeholder>
				)
			case 4004: 
				return (
					<Placeholder
						icon={<Icon56ErrorOutline/>}
						header="Ошибка!"
						action={<Button onClick={() => { window.location.reload() }}> Перезагрузить страницу</Button>}
						stretched={true}
					>
						Ошибка wss onerror.
					</Placeholder>
				)
			case 1006: 
				return (
					<Placeholder
						icon={<Icon56ErrorOutline/>}
						header="Ошибка!"
						action={<Button onClick={() => { window.location.reload() }}> Перезагрузить страницу</Button>}
						stretched={true}
					>
						Соединение потеряно, перезагрузите страницу.
					</Placeholder>
				)
			default:
				return (
					<Placeholder
						icon={<Icon56ErrorOutline/>}
						header="Ошибка!"
						action={<Button onClick={() => { window.location.reload() }}> Перезагрузить страницу</Button>}
						stretched={true}
					>
						Неизвестная ошибка wss.
					</Placeholder>
				)
		}
	}

	render() {
		const { id, wssCloseCode } = this.props
		return (
			<Panel theme="white" id={id}>
				{ this.renderError(wssCloseCode) }
			</Panel>
		)
	}
}

export default WssErrors;