import React from 'react';
import { Alert, Snackbar, Avatar  } from '@vkontakte/vkui';
import app from './app';

import Icon28InfoOutline from '@vkontakte/icons/dist/28/info_outline';

export function showAnswerMessage(title, message, callbackYes = () => {}, callbackNo = () => {}) {
	showAlert(
		<Alert
			actions={[{
				title: 'Отмена',
				autoclose: true,
				style: 'cancel',
				action: () => callbackNo(true),
			},{
				title: 'Хорошо',
				autoclose: true,
				style: 'destructive',
				action: () => callbackYes(true),
			}]}
			onClose={() => { closeAlert() }}
		>
			<h2>{title}</h2>
			<p>{message}</p>
		</Alert>
	)
}

export function showInfoMessage(title, message, callback = () => {}) {
	showAlert(
		<Alert
			actions={[{
				title: 'Хорошо',
				autoclose: true,
				style: 'cancel'
			}]}
			onClose={() => { closeAlert(callback) }}
		>
			<h2>{title}</h2>
			<p>{message}</p>
		</Alert>
	)
}

export function showErrorMessage(title, message, callback = () => {}) {
	showAlert(
		<Alert
			actions={[{
				title: 'Хорошо',
				autoclose: true,
				style: 'cancel'
			}]}
			onClose={() => { closeAlert(callback) }}
		>
			<h2>{title}</h2>
			<p>{message}</p>
		</Alert>
	)
}

export function showSnackInfoMessage(message, callback = () => {}) {
	showSnackbar(
		<Snackbar
			duration={1000}
			layout="horizontal"
			onClose={() => { closeSnackbar(callback) }}
			before={<Avatar style={{backgroundColor: 'var(--accent)'}} size={24}><Icon28InfoOutline fill="#fff" width={16} height={16} /></Avatar>}
		>
			{message}
		</Snackbar>
	)
}

function showAlert(alert) {
	app.setState({
		popout: alert
	})
}

function closeAlert(callback = () => {}) {
	app.setState({
		popout: null
	}, callback)
}


function showSnackbar(alert) {
	app.setState({
		snackbar: alert
	})
}

function closeSnackbar(callback = () => {}) {
	app.setState({
		snackbar: null
	}, callback)
}

