import React from 'react';
import { Panel, Div, Cell, Button, List } from '@vkontakte/vkui';
import Header from '../../components/Header/Header';
import moment from 'moment';
import { declOfNum } from '../../func/utils';
import { getVkPay } from '../../func/vk';
import { showErrorMessage } from '../../func/alert';
import { setTransaction } from '../../func/back';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCrown } from '@fortawesome/free-solid-svg-icons'

import config from '../../config';

class BuyVip extends React.Component {
	
	constructor(props) {
		super(props);

		this.state = {
			disabled_buttons: false,
		};
	}

	buyVip = (e) => {
		const { tarif_index } = e.currentTarget.dataset
		const tarif = config.vip_tarif[tarif_index]
		if (tarif) {
			this.setState({
				disabled_buttons: true,
			}, () => {
				const { id, summ } = tarif
				setTransaction(id, summ, (response) => {
					const { status } = response
					if (status) {
						getVkPay(summ, config.vkPay_description,  (successCallback) => {
							this.setState({ disabled_buttons: false })
						},  (errorCallback) => {
							showErrorMessage("Ошибка!", "Вы закрыли окно платежа не оплатив.")
							this.setState({ disabled_buttons: false })
						})
					}
				})
			})
		}
	}

	render() {
		const { disabled_buttons } = this.state
		const { id, snackbar, navigator, profile } = this.props
		const MomentEndVip = moment.unix(profile.endVip).format("DD.MM.gggg HH:mm")
		return (
			<Panel theme="white" id={id}>
				<Header panelBack={ navigator.goPanelBack } name="VIP статус"/>
				{
					/* Уведомления */
					snackbar 
				}
				
				<div className="__BuyVip_title flex_center pb-15 pt-10">
					<FontAwesomeIcon className="pr-10" size="1x" icon={faCrown} /> 
					<div>VIP статус</div>
				</div>

				{
					(profile.itsVip) ?
						<div>
							<div className="__BuyVip_title flex_center">
								Подключен 
							</div>
							<div className="__BuyVip_title flex_center">
								до {MomentEndVip}
							</div>
						</div>
					: 
						null
				}

				<Div>
					<b>Дает возможность:</b>
					<div>— просматривать аватарки пользователей без эффектов</div>
					<div>— фотографии в диалогах без эфектов</div>
					<div>— неограниченное количество поиска</div>
					<div>— над аватаркой появится золотая рамка и корона с текстом VIP</div>
					<div>— видеть город собеседника</div>
					<div>— отправлять ссылки</div>
				</Div>

				<List>
					{
						config.vip_tarif.map((item, i) => {
							return (
								<Cell key={i} indicator={<Button disabled={disabled_buttons} data-tarif_index={i} onClick={this.buyVip}>{item.summ} {declOfNum(item.summ, ['рубль', 'рубля', 'рублей'])}</Button>}>{item.description}</Cell>
							)
						})
					}
				</List>

			</Panel>
		)
	}
}

export default BuyVip;