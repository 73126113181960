import app from './app';
import config from '../config';
import { getSystemAlbumId, setSystemAlbumId, uploadPhoto } from './back';
import { photosGetAlbums, photosCreateAlbum, photosGetUploadServer, photosSave } from './generate/methods/photos';



export function upload(file, callback) {
	app.setState({ progress: 30 })

	getSystemAlbumId(({aid}) => {
		__testAid(aid, (isValid) => {
			app.setState({ progress: 60 })
			if (isValid) {
				app.setState({ progress: 80 })
				//альбом точно есть
				uploadInAlbum(file, aid, (jsonFile) => {
					app.setState({ progress: 100 })
					callback(jsonFile)
				})
			} else {
				//такого альбома уже нет
				photosCreateAlbum(config.access_token, config.photos, (response) => {
					const { id } = response
					app.setState({ progress: 60 })
					setSystemAlbumId(id, (ok) => {
						app.setState({ progress: 80 })
						if (ok) {
							//Альбом удачно записан
							uploadInAlbum(file, id, (jsonFile) => {
								app.setState({ progress: 100 })
								callback(jsonFile)
							})
						} else {
							console.log('Ошибка создания альбома')
						}
					})
				})
			}

		})
	})
}
/*
	Есть ли альбом который у нас в бд у пользователя или он его уже удалил
*/
function __testAid(aid, callback) {
	photosGetAlbums(config.access_token, {
		owner_id: +config.params.vk_user_id,
		album_ids: aid,		
	}, (response) => {
		callback(__findAlbum(aid, response.items))
	})
}

function __findAlbum(myaid, obj) {
	if (obj.length > 0) {
		let test = obj.findIndex((item) => { if (Number(item.id) === Number(myaid)) { return true } else { return false } });
		if (test !== -1) {
			return true
		} else {
			return false
		}			
	} else {
		return false
	}
}

function uploadInAlbum(file, aid, callback) {
	photosGetUploadServer(config.access_token, {
		album_id: aid
	}, (UploadServer) => {
		const { upload_url } = UploadServer
		uploadPhoto(file, upload_url, (response) => {
			photosSave(config.access_token, {
				album_id: response.upload.aid,
				server: response.upload.server,
				photos_list: response.upload.photos_list,
				hash: response.upload.hash,
			}, (isSaved) => {
				callback(isSaved)
			})
		})
	})
}