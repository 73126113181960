import React from 'react';
import { Panel, Div, Button, Spinner, Input, FormLayout, RangeSlider } from '@vkontakte/vkui';

import { getToken } from '../../func/vk';
import { startApp, goStartPanel } from '../../func/init';
import { setProfile, getProfile } from '../../func/back';
import { showErrorMessage } from '../../func/alert';

import config from '../../config';
import moment from "moment";

import SexChange from '../../components/SexChange/SexChange';

import './style.css';

class Welcome extends React.Component {
	constructor(props) {
		super(props);
		const { userInfo } = config
		const { city, country } = userInfo
		const cityId = (city && city.id) ? city.id : 0
		const countryId = (country && country.id) ? country.id : 0 

		this.state = {
			wait: true,
			profile: {
				age: this.getAgeFromBdate(userInfo.bdate),
				city: cityId,
				country: countryId,
				sex: userInfo.sex || 1,
				findSex: (userInfo.sex) ? (userInfo.sex === 1) ? 2 : 1 : 2,
				findAgeMin: 18,
				findAgeMax: 21,
				attempts: 30,
			},
		};
	}

	componentDidMount() {
		getProfile((response) => {
			const { profile } = response
			if (profile) {
				this.setState({
					wait: false,
					profile: {
						...profile
					}
				})
			} else {
				this.setState({ wait: false })
			}
		})
	}

	startPril = () => {
		//запросить права.
		//запустить приложение.
		const { profile } = this.state
		const { userScope } = this.props
		const { needScope, access_token } = config
		const { age, city, country, sex, findSex, findAgeMin, findAgeMax } = profile
		if (+age < 16 || +age > 60) {
			showErrorMessage('Ошибка!','Возраст должен быть выше 16')
		} else {
			this.setState({
				wait: true
			}, () => {
				//отправляем на бэк то что заполнил пользователь
				setProfile(age, city, country, sex, findSex, findAgeMin, findAgeMax, () => {
					if (goStartPanel(userScope, needScope)) {
						if (needScope.length > 0) {
							getToken(needScope, (response) => {
								startApp(response, true, profile)
							}, (error) => {
								this.setState({ wait: false })
							})	
						} else {
							
							startApp({access_token:access_token, scope:userScope.join(',')}, true, profile)
						}
					} else {
						this.setState({ wait: false })
					}
				})
			})
		}
	}

	onChangeSex = (sex) => {
		this.setState((state) => ({
			profile: {
				...state.profile,
				sex: sex 
			}
		}));
	}

	onChangeFindSex = (sex) => {
		this.setState((state) => ({
			profile: {
				...state.profile,
				findSex: sex 
			}
		}));
	}

	onChange = (e) => {
		const { name, value } = e.currentTarget;
		this.setState((state) => ({
			profile: {
				...state.profile,
				[name]: value 
			}
		}));
	}

	getAgeFromBdate = (bdate) => {
		if (bdate) {
			const arrBdate = bdate.split('.')
			if (bdate) {
				if (arrBdate.length === 3) {
					return moment().diff(moment((bdate), 'D.M.YYYY', true), 'years');
				}
			}		
		} else {
			return 21;
		}
	}

	onChangeFindAge = (e) => {
		this.setState((state) => ({
			profile: {
				...state.profile,
				findAgeMin: e[0],
				findAgeMax: e[1],
			}
		}));
	}

	render() {
		const { wait, profile } = this.state
		const { id } = this.props
		return (
			<Panel theme="white" id={id} name="Анкета">
				{
					(wait) ?
						<Spinner size="regular" style={{ marginTop: 20 }} />
					: 
						<div>
							<div className="flex_center ___welcomeTitle">Кто вы?</div>
								<Div>
									<SexChange
										onChange={this.onChangeSex}
										sex={profile.sex || 1}
									/>
								</Div>
								<FormLayout>
									<Input 
										top="Введите ваш возраст" 
										type="number"
										pattern="\d*"
										min="16" 
										max="60"
										value={String(profile.age)}
										name="age"
										status={+profile.age >= 16 && +profile.age <= 60 ? 'valid' : 'error'}
										onChange={this.onChange}
									/>
								</FormLayout>
								<div className="flex_center ___welcomeTitle pt-20">Кого ищем?</div>

								<Div>
									<SexChange
										onChange={this.onChangeFindSex}
										sex={profile.findSex || 1}
									/>
								</Div>
								<FormLayout>
									<RangeSlider
										top={`От ${profile.findAgeMin} до ${profile.findAgeMax}`}
										min={16}
										max={60}
										step={1}
										onChange={this.onChangeFindAge}
										value={[profile.findAgeMin, profile.findAgeMax] || [18,21]}
									/>
								</FormLayout>
								<Div className="flex_center">
									<Button 
										size='l' 
										onClick={this.startPril}
									>
										Сохранить настройки
									</Button>
								</Div>
							</div>
				}
			</Panel>
		)
	}
}

export default Welcome;