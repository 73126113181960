let config = {
	app_id: null, //ид приложения
	admin_app: [152873202, 33873307], //админы
	group_id: 9568985,//группа для принятия платежей и отправки уведомлений
	params: null, //параметры запуска
	hash: null, //Хеш приложения при запуске
	wss: true, //подключаться к wss серверу
	userInfo:{},//
	wssConnection: null, // connection для wss
	uploadUrl: (process.env.NODE_ENV === 'development') ? 'https://dating.kupiapp2.ru/api/upload.php' : 'https://dating.kupiapp2.ru/api/upload.php', //url загрузки
	wssUrl: (process.env.NODE_ENV === 'development') ? 'wss://dating.kupiapp2.ru/wss' : 'wss://dating.kupiapp2.ru/wss', //url wss сервера
	backUrl: (process.env.NODE_ENV === 'development') ? 'https://dating.kupiapp2.ru/api/index.php' : 'https://dating.kupiapp2.ru/api/index.php', //url бекенда
	api_version: "5.103", //версия апи
	access_token: "", //ключ доступа апи
	needScope: [""], //права которые нужны по умолчанию
	desktopHeader: true, //кастомный хедер для десктопа
	startPanel: true, //показывать стартовую панель. Если true будет 1 раз загружать стартовую панель.
	userFields: ["photo_50", "photo_200", "online", "verified", "sex"], //данные которые нужно запрашивать у пользователей
	groupFields: ['verified', 'members_count'], //данные которые нужно запрашивать у групп
	mobileConsole: false, //подключить в режиме разработки мобильную консоль для отладки iOS
	mibileConsoleCDN: 'cdn.jsdelivr.net/npm/eruda', //CDN скрипта с мобиьной консолью
	photos: { //информация по альбомам
		title: "Найди пару", //название альбома который создаётся у пользвоателя
		description:"Альбом для фотографий в сервисе Найди пару", //описание альбома, который создаётся у пользвоателя
		privacy_view:"only_me",  //права доступа для альбома
	},
	bugFix_restoreApp: false,
	attempts_tarif: [
		{
			id: 1,
			description: '100 попыток',			//1
			summ: 29,
		},
		{
			id: 2,
			description: 'Безлимит на день',	//2
			summ: 58,
		},
		{
			id: 3,
			description: 'Безлимит на неделю',	//3
			summ: 98,
		},
	],
	vip_tarif: [
		{
			id: 4,
			description: '2 недели',			//4
			summ: 98,
		},
		{
			id: 5,
			description: '1 месяц',				//5
			summ: 118,
		},
		{
			id: 6,
			description: '3 месяца',			//6
			summ: 145,
		},
	],
	vkPay_description: "Оплата",
	set: function(data, callback = () => {}) { //смена конфига
		for (let key in data) {
			this[key] = data[key]
		}
		callback(true)
	}
}

export default config;