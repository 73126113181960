import config from '../config';
import { showErrorMessage } from './alert';
import 'whatwg-fetch'

export function sentReq(json_data, callback = () => {}, callback_error = () => {}) {
	let url = config.backUrl
	json_data.params = config.params
	
	fetch(url, {
		method: 'post',
		headers: {
			'Accept': 'application/json, text/plain, */*',
			'Content-Type': 'application/x-www-form-urlencoded'
		},
		body: 'data=' + encodeURIComponent(JSON.stringify(json_data))
	})
	.then(function (response) {
		return response.json();
	})
	.then((data) => {
		callback(data);
	})
	.catch(function (error) {
		callback_error(error)
	});
}

/*
	Отправляет файл на сервер
*/
export function sentFile(json_data, file, callback = () => {}, callback_error = () => {}) {
	let formData = new FormData();

	for (let key in json_data) {
		formData.append(key, json_data[key]);
	}
	formData.append('file', file);

	fetch(config.uploadUrl, {
	  method: 'POST',
	  body: formData
	})
	.then(function (response) {
		return response.json();
	})
	.then((data) => {
		callback(data);
	})
	.catch(function (error) {
		callback_error(error)
	});
}

export function firstQuery(callback = () => {}) {
	//тут первые запросы к апи
	callback(true)
}

/*
	тестовый запрос
*/
export function getTestQuery(callback = () => {}) {
	sentReq({command: "test"}, (response) => {
		if (!response.error) {
			callback(response.response)
		} else {
			showErrorMessage("Ошибка!", response.text_error)
		}
	}, (error) => {
		console.log(error)
	})
}

/*
	тестовый запрос
*/
export function getTestLongQuery(callback = () => {}) {
	sentReq({command: "test_long"}, (response) => {
		if (!response.error) {
			callback(response.response)
		} else {
			showErrorMessage("Ошибка!", response.text_error)
		}
	}, (error) => {
		console.log(error)
	})
}

/*
	тестовый запрос
*/
export function vkApiTest(user_ids, callback = () => {}) {
	sentReq({command: "vkApiTest", user_ids:user_ids}, (response) => {
		if (!response.error) {
			callback(response.response)
		} else {
			showErrorMessage("Ошибка!", response.text_error)
		}
	}, (error) => {
		console.log(error)
	})
}

/*
	получает id альбома пользователя
*/
export function getSystemAlbumId(callback = () => {}) {
	sentReq({command: "getSystemAlbumId"}, (response) => {
		if (!response.error) {
			callback(response.response)
		} else {
			showErrorMessage("Ошибка!", response.text_error)
		}
	}, (error) => {
		console.log(error)
	})
}

/*
	ставит альбом пользователю
*/
export function setSystemAlbumId(aid, callback = () => {}) {
	sentReq({command: "setSystemAlbumId", aid:aid}, (response) => {
		if (!response.error) {
			callback(response.response)
		} else {
			showErrorMessage("Ошибка!", response.text_error)
		}
	}, (error) => {
		console.log(error)
	})
}

export function uploadPhoto(file, upload_url, callback) {
	sentFile({upload_url:upload_url, params:JSON.stringify(config.params)}, file, (response) => {
		if (!response.error) {
			callback(response.response)
		} else {
			showErrorMessage("Ошибка!", response.text_error)
		}
	}, (error) => {
		console.log(error)
	})
}

export function setProfile(age, city, country, sex, findSex, findAgeMin, findAgeMax, callback = () => {}) {
	sentReq({command: "setProfile", age:age, city:city, country:country, sex:sex, findSex:findSex, findAgeMin:findAgeMin, findAgeMax:findAgeMax}, (response) => {
		if (!response.error) {
			callback(response.response)
		} else {
			showErrorMessage("Ошибка!", response.text_error)
		}
	}, (error) => {
		console.log(error)
	})
}

export function getProfile(callback = () => {}) {
	sentReq({command: "getProfile"}, (response) => {
		if (!response.error) {
			callback(response.response)
		} else {
			showErrorMessage("Ошибка!", response.text_error)
		}
	}, (error) => {
		console.log(error)
	})
}

export function getSavedDialogs(offset, count, ts, callback = () => {}) {
	sentReq({command: "getSavedDialogs", offset:offset, count:count, ts: ts}, (response) => {
		if (!response.error) {
			callback(response.response)
		} else {
			showErrorMessage("Ошибка!", response.text_error)
		}
	}, (error) => {
		console.log(error)
	})
}

export function getHystoryDialog(offset, count, ts, uid, callback = () => {}) {
	sentReq({command: "getHystoryDialog", offset:offset, count:count, ts:ts, uid:uid}, (response) => {
		if (!response.error) {
			callback(response.response)
		} else {
			showErrorMessage("Ошибка!", response.text_error)
		}
	}, (error) => {
		console.log(error)
	})
}

export function setIsNotify(isNotify, callback = () => {}) {
	sentReq({command: "setIsNotify", isNotify:isNotify}, (response) => {
		if (!response.error) {
			callback(response.response)
		} else {
			showErrorMessage("Ошибка!", response.text_error)
		}
	}, (error) => {
		console.log(error)
	})
}

export function setTransaction(id, summ, callback = () => {}) {
	sentReq({command: "setTransaction", id:id, summ:summ}, (response) => {
		if (!response.error) {
			callback(response.response)
		} else {
			showErrorMessage("Ошибка!", response.text_error)
		}
	}, (error) => {
		console.log(error)
	})
}