import React from 'react';
import { Panel, Group, List, Cell, Spinner } from '@vkontakte/vkui';
import Header from '../../components/Header/Header';
import { apiGetCountries } from '../../func/vk';
import config from '../../config';
import app from '../../func/app';

import Icon24Done from '@vkontakte/icons/dist/24/done';

class ChangeCountry extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			count: 0,
			items: [],
			isLoad: false,
		};
	}

	componentDidMount() {
		const { access_token } = config
		apiGetCountries(access_token, 0, 100, 0, (response) => {
			this.setState((state) => ({
				...response,
				isLoad: true,
			}))
		})
	}

	render() {

		const { count, items, isLoad } = this.state
		const { id, snackbar, navigator, profile } = this.props
		return (
			<Panel theme="white" id={id}>
				<Header panelBack={ navigator.goPanelBack } name="Выбор страны"/>
				{
					/* Уведомления */
					snackbar 
				}
				{
					(isLoad) ?
						<Group>
							<List>
								<Cell
									asideContent={profile.country === 0 ? <Icon24Done fill="var(--accent)" /> : null}
									onClick={
										() => {
											app.setState((state) => ({
												profile: {
													...state.profile,
													country: 0,
													city: 0
												},						
											}), navigator.goPanelBack)
										}
									}
								>
									Не выбрана
								</Cell>
								{
									(count > 0) ?
										items.map((item) => {
											return (
												<Cell
													key={item.id}
													asideContent={profile.country === item.id ? <Icon24Done fill="var(--accent)" /> : null}
													onClick={
														() => {
															app.setState((state) => ({
																profile: {
																	...state.profile,
																	country: item.id,
																	city: 0
																},
																apiCountriesData: [...state.apiCountriesData, item]
															}), navigator.goPanelBack)
														}
													}
												>
													{item.title}
												</Cell>
											)
										})
									: null
								}
							</List>
						</Group>
					: 
						<Spinner size="regular" style={{ marginTop: 20 }} />
				}

			</Panel>
		)
	}
}

export default ChangeCountry;