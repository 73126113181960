import app from './app';
import config from '../config';
import { showErrorMessage, showSnackInfoMessage } from './alert';
import { getNewRequestId, isJsonString } from './utils';
import { animateScroll as scroll } from 'react-scroll'

//массив с коллбеками
let stack = [];

export function wssInit(callback = () => {}) {
	const { wss, wssConnection } = config
	if (wss && !wssConnection) {
			const wssConnection = new WebSocket(config.wssUrl); // конектимся к сокету

			wssConnection.onopen = () => {
				app.setState({
					wssCloseCode: false,
				}, () => {
					config.set({
						wssConnection: wssConnection
					}, () => {
						callback(true)
					})
				})
			}

			wssConnection.onclose = (e) => {
				const { code } = e
				app.setState({
					isLoad: false,
					wssCloseCode: code
				}, () => {
					config.set({
						wssConnection: null
					})
				})
			}

			wssConnection.onerror = (error) => {
				console.log('WebSocket Error ', error);
			}

			wssConnection.onmessage = (e) => {
				if (isJsonString(e.data)) {
					const data = JSON.parse(e.data)
					const { requestId, command } = data
					//const { vk_user_id } = config.params
					switch (command) {
						case "ping":
							__onPing()
						break;
						case "partnerSayYes":
							__onPartnerSayYes()
						break;
						case "partnerSayNo":
							__onPartnerSayNo(data)
						break;
						case "delDialog":
							__onDelDialog(data)
						break;
						case "notifyNewMessage":
							__onNotifyNewMessage()
						break;
						case "newMessage":
							__onNewMessage(data)
						break;
						case "partnerDisableDialog":
							__onPartnerDisableDialog(data)
						break;
						case "saveDialog":
							__onSaveDialog(data)
						break;
						case "goChat":
							__onGoChat()
						break;
						case "getInit": //регистрация сокета
							__onGetInit()
						break;
						case "updateTarif": //регистрация сокета
							__onUpdateTarif(data)
						break;
						default:
							const func = stack.filter(({id}) => { return id === requestId })
							if (func.length > 0) {
								func[0].action(data)
							}
					}
				}
			}
	} else {
		callback(true)
	}
}

function __onUpdateTarif(data) {
	const { profile } = data
	app.setState((state) => ({
		profile: {
			...state.profile,
			...profile
		}
	}))
}

function __onGetInit() {
	sentWss({command:"init"}, () => {
		console.log('Успешная регистрация сокета на беке')
	})
}

function __onGoChat() {
	app.setState({
		answer: false,
		partnerSayYes: false,
		partnerSayNo: false,
		twist: false,
		message: "",
		chat: [],
		heartBeatAnimate: false,
	}, app.goChat)
}

function __onSaveDialog(data) {
	const { message, partnerUid } = data
	app.setState((state) => ({
		chat: (partnerUid === state.partner.uid) ? [...state.chat, message] : [...state.chat],
		isSaved: true,
		profiles: (state.partner) ? [state.partner, ...state.profiles] : [...state.profiles],
	}), () => {
		scroll.scrollToBottom({
			duration: 0,
			delay: 0,
		})
	})
}

function __onPartnerDisableDialog(data) {
	const { message, partnerUid } = data
	app.setState((state) => ({
		chat: (partnerUid === state.partner.uid) ? [...state.chat, message] : [...state.chat],
	}), () => {
		scroll.scrollToBottom({
			duration: 0,
			delay: 0,
		})
	})
}

function __onNewMessage(data) {
	console.log(data)
	const { message, partnerUid } = data
	app.setState((state) => ({
		chat: (partnerUid === state.partner.uid) ? [...state.chat, message] : [...state.chat],
	}), () => {
		scroll.scrollToBottom({
			duration: 0,
			delay: 0,
		})
	})
}

function __onNotifyNewMessage() {
	/* мутная схема */
	app.setState((state) => {
		if (state.profile.isNotify) {
			showSnackInfoMessage('Новое сообщение из сохранённого диалога')
		} else {
			return {}
		}
	})
}

function __onDelDialog(data) {
	const uidDelDialog = data.uid
	app.setState((state) => {
		const index = state.profiles.findIndex(({uid}) => { return (uidDelDialog === uid) })
		return {
			profiles: (index !== -1) ? [...state.profiles.slice(0, index), ...state.profiles.slice(index + 1)] : [...state.profiles]
		}
	})
}

function __onPartnerSayNo(data) {
	const { twister, message, partnerUid } = data
	//sayNo(partnerUid, (response) => { })

	app.setState((state) => {
		return {
			heartBeatAnimate: false,
			partnerSayNo: (partnerUid === state.partner.uid) ? true : state.partnerSayNo,
			twist: (partnerUid === state.partner.uid) ? false : state.twist,
			isEpic: (partnerUid === state.partner.uid && twister) ? true : state.isEpic,
			chat: (partnerUid === state.partner.uid && twister && message) ? [...state.chat, message] : [...state.chat],
		}
	}, () => {
		scroll.scrollToBottom({
			duration: 0,
			delay: 0,
		})
	})
}

function __onPartnerSayYes() {
	app.setState({ partnerSayYes: true, })
}

function __onPing() {
	sentWss({command:"pong"})
}

export function sentWss(json_data, successCallback = undefined) {
	const requestId = getNewRequestId();
	const { wssConnection, params } = config
	if (wssConnection) {
		json_data.params = params
		json_data.requestId = requestId

		if (successCallback !== undefined) {
			let clb = function callback(data) {
				const id  = data.requestId

				if (id === requestId) {
					successCallback(data);
				} else {
					successCallback(false);
				}
				unsubscribe(id)
			}
			
			subscribe(clb, requestId)
		}
		wssConnection.send(JSON.stringify(json_data));
	} else {
		console.log('Не получилось отправить wss', json_data)
	}
}

function subscribe(clb, requestId) {
	stack.push({
		id: requestId,
		action: clb
	})
}

function unsubscribe(requestId) {
	const index = stack.findIndex(({id}) => {
		return requestId === id
	})

	if (index !== -1) {
		stack = [
			...stack.slice(0, index),
			...stack.slice(index + 1)
		]
	} 
}


/*
	деактивируем диалог
*/
export function disableDialog(uid, callback = () => {}) {
	sentWss({command:"disableDialog", uid:uid}, (response) => {
		if (!response.error) {
			callback(response.response)
		} else {
			showErrorMessage("Ошибка!", response.text_error)
		}
	})
}

/*
	Говорим партнёру ДА
*/
export function sayYes(uid, callback = () => {}) {
	sentWss({command:"sayYes", uid:uid}, (response) => {
		if (!response.error) {
			callback(response.response)
		} else {
			showErrorMessage("Ошибка!", response.text_error)
		}
	})
}

/*
	Говорим партнёру НЕТ
*/
export function sayNo(uid, callback = () => {}) {
	sentWss({command:"sayNo", uid:uid}, (response) => {
		if (!response.error) {
			callback(response.response)
		} else {
			showErrorMessage("Ошибка!", response.text_error)
		}
	})
}

/*
	Начинает поиск партнёра
*/
export function twist(callback = () => {}) {
	sentWss({command:"twist"}, (response) => {
		if (!response.error) {
			callback(response.response)
		} else {
			showErrorMessage("Ошибка!", response.text_error)
		}
	})
}

/*
	Останавливает поиск партнёра
*/
export function stopTwist(callback = () => {}) {
	sentWss({command:"stopTwist"}, (response) => {
		if (!response.error) {
			callback(response.response)
		} else {
			showErrorMessage("Ошибка!", response.text_error)
		}
	})
}

/*
	Тестовый запрос к бд через сокет
*/
export function getTestQuerySocket(callback = () => {}) {
	sentWss({command:"testBase"}, (response) => {
		if (!response.error) {
			callback(response.response)
		} else {
			showErrorMessage("Ошибка!", response.text_error)
		}
	})
}

/*
	Отправка сообщения собеседнику
*/
export function sendMessage(uid, message, attachment, callback = () => {}) {
	sentWss({command:"sendMessage", uid:uid, message:message, attachment:attachment}, (response) => {
		if (!response.error) {
			callback(response.response)
		} else {
			showErrorMessage("Ошибка!", response.text_error)
		}
	})
}

/*
	Сохранение диалога
*/
export function saveDialog(uid, callback = () => {}) {
	sentWss({command:"saveDialog", uid:uid}, (response) => {
		if (!response.error) {
			callback(response.response)
		} else {
			showErrorMessage("Ошибка!", response.text_error)
		}
	})
}

/*
	Возобновление диалога
*/
export function initCouples(uid, callback = () => {}) {
	sentWss({command:"initCouples", uid:uid}, (response) => {
		if (!response.error) {
			callback(response.response)
		} else {
			showErrorMessage("Ошибка!", response.text_error)
		}
	})
}

/*
	Удаление диалога
*/
export function delDialog(uid, callback = () => {}) {
	sentWss({command:"delDialog", uid:uid}, (response) => {
		if (!response.error) {
			callback(response.response)
		} else {
			showErrorMessage("Ошибка!", response.text_error)
		}
	})
}

/*
	Изменение настроек профиля
*/
export function updateProfile(age, city, country, sex, findSex, findAgeMin, findAgeMax, callback = () => {}) {
	sentWss({command:"updateProfile", age:age, city:city, country:country, sex:sex, findSex:findSex, findAgeMin:findAgeMin, findAgeMax:findAgeMax}, (response) => {
		if (!response.error) {
			callback(response.response)
		} else {
			showErrorMessage("Ошибка!", response.text_error)
		}
	})
}


