import React from 'react';
import { Panel, Group, List, Cell, Spinner, Input, Div } from '@vkontakte/vkui';
import Header from '../../components/Header/Header';
import { apiGetCities } from '../../func/vk';
import config from '../../config';
import app from '../../func/app';

import Icon24Done from '@vkontakte/icons/dist/24/done';

class ChangeCity extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			count: 0,
			items: [],
			isLoad: false,
			isResponse: true,
			search: "",
			region: false,
		};
	}

	componentDidMount() {
		const { access_token } = config
		const { country } = this.props.profile

		apiGetCities(access_token, country, "", 0, 1000, 0, (response) => {
			this.setState((state) => ({
				...response,
				isLoad: true,
				isResponse: true,
				region: false,
			}))
		})
	}

	get items() {
		const { items, search } = this.state
		return items.filter(({title}) => { return (title.toLowerCase().indexOf(search.toLowerCase()) > -1) ? true : false })
	}

	onChange = (e) => {
		const { value } = e.currentTarget

		if (this.items.length === 0 && value.length > 0) {
			const { access_token } = config
			const { country } = this.props.profile	
			this.setState({isResponse: false}, () => {
				apiGetCities(access_token, country, value, 1, 1000, 0, (response) => {
					this.setState((state) => ({
						...response,
						isResponse: true,
						region: true,
					}))
				})
			})
		} 

		if (value.length > 0) {
			this.setState({ search: value })
		} else {
			this.setState({ search: value })
			this.componentDidMount()
		}
	}

	render() {
		const { isLoad, isResponse, region } = this.state
		const { id, snackbar, navigator, profile } = this.props
		console.log(this.state)
		return (
			<Panel theme="white" id={id}>
				<Header panelBack={ navigator.goPanelBack } name="Уведомления"/>
				{
					/* Уведомления */
					snackbar 
				}
				{
					(isLoad) ?
						<div>
							<Group>
								<Div>
									<Input onChange={this.onChange} placeholder="Поиск" type="text" />
								</Div>
							</Group>
							<Group>
								
								<List>
									<Cell
										asideContent={profile.city === 0 ? <Icon24Done fill="var(--accent)" /> : null}
										onClick={
											() => {
												app.setState((state) => ({
													profile: {
														...state.profile,
														city: 0
													},						
												}), navigator.goPanelBack)
											}
										}
									>
										Не выбран
									</Cell>
									{
										(isResponse) ?
											(this.items) ?
												this.items.map((item) => {
													return (
														<Cell
															key={item.id}
															asideContent={profile.city === item.id ? <Icon24Done fill="var(--accent)" /> : null}
															description={(region) ? item.region : null}
															onClick={
																() => {
																	app.setState((state) => ({
																		profile: {
																			...state.profile,
																			city: item.id
																		},
																		apiCitiesData: [...state.apiCitiesData, item]
																	}), navigator.goPanelBack)
																}
															}
														>
															{item.title}
														</Cell>
													)
												})
											: null
										: <Spinner size="regular" style={{ marginTop: 20 }} />
									}
								</List>
							</Group>
						</div>
					: 
						<Spinner size="regular" style={{ marginTop: 20 }} />
				}
			</Panel>
		)
	}
}

export default ChangeCity;