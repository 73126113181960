import React from 'react';
import { Panel, Cell, Avatar, Placeholder, Button, PullToRefresh } from '@vkontakte/vkui';
import Header from '../components/Header/Header';
import { findObjectById, declOfNum } from '../func/utils';
import { delDialog } from '../func/wss';
import { getSavedDialogs } from '../func/back';
import { getMissingUsers, getMissingCity, getMissingCountry } from '../func/vk';
import { showAnswerMessage } from '../func/alert';

import Pagination from '../components/Pagination/Pagination';

import app from '../func/app';
import config from '../config';

import Icon56InfoOutline from '@vkontakte/icons/dist/56/info_outline';

class Dialog extends React.Component {
	constructor(props) {
		super(props);
		const { navigator } = this.props
		navigator.block(false)
		this.state = {
			fetching: false
		};
	}

	goOneDialog = (partner) => {
		const { navigator } = this.props
		app.setState((state) => ({ //тут скидываем все перевенные в первичное состояние
			isEpic: false,
			partner: partner
		}), () => { navigator.goPanel('Chat') })
	}

	delDialog = (e, partner) => {
		e.stopPropagation()
		showAnswerMessage('Удалить диалог?', "При удалении диалога он удалится и у собеседника.", () => {
			const { profiles } = this.props
			const index = profiles.findIndex(({id}) => { return (partner.id === id) })
			if (index !== -1) {
				app.setState((state) => ({ profiles: [...state.profiles.slice(0, index), ...state.profiles.slice(index + 1)] }))
				delDialog(partner.uid, () => {})
			}
		})
	}

	showNextPage = (callback) => {
		const { dialogCount, dialogOffset, dialogTs, apiUsersData, apiCitiesData, apiCountriesData } = this.props
		getSavedDialogs(dialogOffset+dialogCount, dialogCount, dialogTs, (response) => {
			const { profiles } = response
			const needUids = profiles.map(({uid}) => { return uid})

			let needCity= profiles.map(({city}) => { return (city) ? city : null})
			needCity = needCity.filter((x) => { return (x) })
				
			let needCountry= profiles.map(({country}) => { return (country) ? country : null})
			needCountry = needCountry.filter((x) => { return (x) })

			const availableCity = apiCitiesData.map(({id}) => { return id})
			const availableCountry = apiCountriesData.map(({id}) => { return id})
			const availableUids = apiUsersData.map(({id}) => { return id})
			
			getMissingCity(availableCity, config.access_token, needCity, (apiCitiesData) => {
				getMissingCountry(availableCountry, config.access_token, needCountry, (apiCountriesData) => {
					getMissingUsers(availableUids, config.access_token, needUids, (info) => {
						app.setState((state) => ({
							apiUsersData: (info) ? [...state.apiUsersData, ...info] : [...state.apiUsersData],
							apiCountriesData: (apiCountriesData) ? [...state.apiCountriesData, ...apiCountriesData] : [...state.apiCountriesData],
							apiCitiesData: (apiCitiesData) ? [...state.apiCitiesData, ...apiCitiesData] : [...state.apiCitiesData],
							profiles: [...state.profiles, ...profiles],
							dialogOffset: dialogOffset+dialogCount
						}), () => {
							callback(true)
						})
					})
				})
			})
		})
	}

	onRefresh = () => {
		const { apiCitiesData, apiCountriesData, apiUsersData } = this.props
		this.setState({ fetching: true }, () => {
			const dialogTs = Math.floor(Date.now() / 1000) //текущий таймстамп

			getSavedDialogs(0, 10, dialogTs, (response) => {
				const { profiles, countDialog } = response
				const needUids = profiles.map(({uid}) => { return uid})
				
				let needCity= profiles.map(({city}) => { return (city) ? city : null})
				needCity = needCity.filter((x) => { return (x) })
				
				let needCountry= profiles.map(({country}) => { return (country) ? country : null})
				needCountry = needCountry.filter((x) => { return (x) })

				const availableCity = apiCitiesData.map(({id}) => { return id})
				const availableCountry = apiCountriesData.map(({id}) => { return id})
				const availableUids = apiUsersData.map(({id}) => { return id})

				/* Получаем страну и город из базы ВК по их id */
				getMissingCity(availableCity, config.access_token, needCity, (apiCitiesData) => {
					getMissingCountry(availableCountry, config.access_token, needCountry, (apiCountriesData) => {
						getMissingUsers(availableUids, config.access_token, needUids, (info) => {
							app.setState((state) => ({
								apiUsersData: (info) ? [...state.apiUsersData, ...info] : [...state.apiUsersData],
								apiCountriesData: (apiCountriesData) ? [...state.apiCountriesData, ...apiCountriesData] : [...state.apiCountriesData],
								apiCitiesData: (apiCitiesData) ? [...state.apiCitiesData, ...apiCitiesData] : [...state.apiCitiesData],
								profiles: [...profiles],
								dialogOffset: 0,
								dialogTs: dialogTs,
								dialogCount: 10,
								countDialog: countDialog,
							}), () => {
								this.setState({ fetching: false })
							})
						})
					})
				})

			})
		})
	}

	render() {
		const { id, apiUsersData, profiles, profile, countDialog, dialogCount, dialogOffset, snackbar, apiCitiesData } = this.props
		const { fetching } = this.state
		return (
			<Panel theme="white" id={id}>
				<Header name="Диалоги"/>
				{ 
					/* Уведомления */
					snackbar 
				}

				<PullToRefresh onRefresh={this.onRefresh} isFetching={fetching}>
					<div>
						{
							(profiles.length > 0) ? 

								profiles.map((partner, i) => {
									let user = findObjectById(apiUsersData, +partner.uid)
									let city = findObjectById(apiCitiesData, +partner.city)
									return (
										<Cell 
											key={i}
											before={ 
												<Avatar className={(!profile.itsVip) ? (partner.itsVip) ? `thisHidden thisVip` : `thisHidden` : (partner.itsVip) ? `thisVip` : ``} src={user.photo_50} size={48} />
											}
											onClick={() => { this.goOneDialog(partner) }}
											description={
												<div>
													{partner.age} {declOfNum(partner.age, ['год', 'года', 'лет'])}
													{ (city && profile.itsVip) ? `, ${city.title}` : null }
												</div>
											}
											indicator={
												<Button onClick={(e) => {this.delDialog(e, partner)}}>Удалить</Button>
											}
										>
											{user.first_name}
										</Cell>
									)
								})

							:
								<Placeholder
									icon={<Icon56InfoOutline />}
									title="Сохранённые диалоги"
									stretched={true}
								>
									Тут будет список всех диалогов, которые Вы сохранили
								</Placeholder>
						}
						<Pagination
							count={countDialog}
							navCount={dialogCount}
							navOffset={dialogOffset}
							showNextPage={this.showNextPage}
						/>
					</div>
				</PullToRefresh>

			</Panel>
		)
	}
}

export default Dialog;