import React from 'react';
import { Panel, Button, List, Cell } from '@vkontakte/vkui';
import Header from '../components/Header/Header';
import { stopTwist, twist, sayNo, sayYes } from '../func/wss';
import { findObjectById, declOfNum } from '../func/utils';
import { getMissingUsers, getMissingCity, getVkPay } from '../func/vk';
import { setTransaction } from '../func/back';
import { showErrorMessage } from '../func/alert';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeartbeat, faVenus, faMars } from '@fortawesome/free-solid-svg-icons'
//хелперы
import app from '../func/app';
import config from '../config';
import moment from 'moment';

class Twister extends React.Component {
	constructor(props) {
		super(props);
		//app.setState((state) => ({ isEpic: true }))
		this.state = {
			pushSayYes: false,
			pushSayNo: false,
			warningAttempts: true,
		};
	}

	stopTwist = () => {
		const { navigator } = this.props
		navigator.block(false)
		app.setState((state) => ({ //тут скидываем все перевенные в первичное состояние
			isEpic: true,
			answer: false,
			partnerSayNo: false,
			partnerSayYes: false,
			twist: false,
			chat: [],
			heartBeatAnimate: false
		}))
		stopTwist((response) => {})
	}

	isValidTwist = (itsVip, attempts, itsUnlim, callback) => {
		const { warningAttempts } = this.state

		if (itsVip) {
			callback(true)
		} else {
			if (itsUnlim) {
				callback(true)
			} else {
				if (attempts > 0) {
					if (attempts === 15 && warningAttempts) { //проверка на предупреждение
						this.setState({ warningAttempts: false }, () => {
							callback(false)
						})
					} else {
						app.setState((state) => ({
							profile: {
								...state.profile,
								attempts: state.profile.attempts - 1
							}
						}), () => {
							callback(true)
						})
					}
				} else {
					callback(false)
				}
			}

		}
	}

	twist = () => {
		const { navigator, apiUsersData, apiCitiesData, profile } = this.props
		this.isValidTwist(profile.itsVip, profile.attempts, profile.itsUnlim, (isGo) => {
			if (isGo) {
				app.goChat = () => { 
					navigator.block(false)
					navigator.goPanel('Chat') 
				}
				//блокируем навигатор
				navigator.block(true)

				app.setState((state) => ({ //тут скидываем все перевенные в первичное состояние
					isEpic: false,
					answer: false,
					partnerSayNo: false,
					partnerSayYes: false,
					twist: true,
					chat: [],
					heartBeatAnimate: true,
				}))
				this.setState({
					pushSayYes: false,
					pushSayNo: false,
				})

				twist((response) => { 
					//крутим рулетку, сокет ищет партнёра и 
					const { uid, city } = response
					const availableCity = apiCitiesData.map(({id}) => { return id })
					const availableUids = apiUsersData.map(({id}) => { return id })

					getMissingUsers(availableUids, config.access_token, [uid], (info) => {
						getMissingCity(availableCity, config.access_token, [city], (cities) => {
							app.setState({ 
								apiCitiesData: (cities) ? [...apiCitiesData, ...cities] : [...apiCitiesData], 
								apiUsersData: (info) ? [...apiUsersData, ...info] : [...apiUsersData],
								answer: true,
								partner: response,
							})
						})
					})
				})
			} else {
				this.setBuyModal()
			}
		})
	}

	setBuyModal = () => {
		const { navigator, profile } = this.props
		navigator.setModal('experemental', 
			<div>
				<div className="__ModalFontTitle">
					Осталось {profile.attempts} попыток.
				</div>
				<div className="__ModalFontDescription">
					Увеличьте количество попыток.
				</div>
			</div>
			, 
			<List>
				{
					config.attempts_tarif.map((item, i) => {
						return (
							<Cell key={i} indicator={<Button data-tarif_index={i} onClick={this.buyAttempts}>{item.summ} {declOfNum(item.summ, ['рубль', 'рубля', 'рублей'])}</Button>}>{item.description}</Cell>
						)
					})
				}
			</List>
		)
	}

	buyAttempts = (e) => {
		const { tarif_index } = e.currentTarget.dataset
		const tarif = config.attempts_tarif[tarif_index]
		if (tarif) {
			const { id, summ } = tarif
			setTransaction(id, summ, (response) => {
				const { status } = response
				if (status) {
					getVkPay(summ, config.vkPay_description,  (successCallback) => {
						this.setState({ disabled_buttons: false })
					},  (errorCallback) => {
						showErrorMessage("Ошибка!", "Вы закрыли окно платежа не оплатив.")
						this.setState({ disabled_buttons: false })
					})
				}
			})
		}
	}

	sayYes = () => {
		const { navigator, partner } = this.props
		this.setState({ pushSayYes: true })
		sayYes(partner.uid, (response) => { 
			if (response.goChat) {
				app.setState({
					answer: false,
					partnerSayYes: false,
					partnerSayNo: false,
					twist: false,
					message: "", //инпут сообщения
					chat: [],
					heartBeatAnimate: false
				})
				navigator.block(false)
				navigator.goPanel('Chat')
			}
		})
	}

	sayNo = () => {
		const { navigator, partner } = this.props
		this.setState({ pushSayYes: false, pushSayNo: true })
		sayNo(partner.uid, (response) => { 
			//возвращаем на окно поиска
			navigator.block(false)
			app.setState({ 
				isEpic: true,
				answer: false, //найден собеседник, вопрос о начале чата
				partnerSayYes: false, // партнёр согласился на диалог
				partnerSayNo: false, // партнёр отказался от дилога
				partner: {}, //анкета партнёра
				twist: false, // идёт поиск 
				heartBeatAnimate: false,
			})
		})
	}

	getUser = (uid) => {
		const { apiUsersData } = this.props
		const user = findObjectById(apiUsersData, +uid)
		if (user) {
			return user
		} else {
			return false
		}
	}

	render() {
		const { pushSayYes, pushSayNo } = this.state
		const { id, profile, answer, partnerSayYes, partnerSayNo, partner, twist, snackbar, apiCitiesData, heartBeatAnimate } = this.props
		const { attempts, itsUnlim, itsVip, endVip, endUnlim } = profile
		let user, city
		if (partner && partner.uid) {
			user = this.getUser(partner.uid)
			city = findObjectById(apiCitiesData, partner.city)
		}

		const MomentEndVip = moment.unix(endVip).format("DD.MM.gggg HH:mm")
		const MomentEndUnlim = moment.unix(endUnlim).format("DD.MM.gggg HH:mm")

		return (
			<Panel theme="white" id={id}>
				<Header name="Чат"/>
				{ 
					/* Уведомления */
					snackbar 
				}
				{
					(answer && !partnerSayNo) ?
						//информация о собеседнике
						<div>
							<div className="flex_center photoBox pt-50">
								<div className={(partner.itsVip) ? `thisVip_1` : ``}>
									<div className="__Twister_avatar">
										<img alt=""  className={(!profile.itsVip) ? `thisHidden_1` : ``} src={user.photo_200}/>
									</div>
								</div>
							</div>

							<div className="flex_center nameBox pt-30">
								{
									(partner.sex === 1) ?
										<FontAwesomeIcon className="pr-5 faVenus" size="3x" icon={faVenus} />
									:
										<FontAwesomeIcon className="pr-5 faMars" size="3x" icon={faMars} />
								}
								<div>
									<div className="fontUserName">{user.first_name}</div>
									<div>
										{partner.age} {declOfNum(partner.age, ['год', 'года', 'лет'])}
										{ (city && profile.itsVip) ? `, ${city.title}` : null }
									</div>
								</div>
							</div>
							
							<div className="__TwisterAnswerBlock">
								{
									(partnerSayYes) ?
										<div>
											<div className="flex_center">{user.first_name} уже {(partner.sex === 1) ? `согласилась` : `согласился`}</div>
											<div className="flex_center">на диалог</div>
										</div>	
									: null
								}
							
								{
									(pushSayYes) ?
										(!partnerSayYes && !partnerSayNo) ? 
											<div>
												<div className="flex_center">Отлично</div>
												<div className="flex_center">мы отправили предложение</div>
											</div>
										: null
									: null
								}
							</div>

							<div className='flex_center'>
								<Button disabled={pushSayYes} className="mr-5" onClick={this.sayYes}>Начать диалог</Button>
								<Button disabled={pushSayNo} level="secondary" onClick={this.sayNo}>Следующий</Button>
							</div>

						</div>
					: 
						<div>
							<div className="fontTitle pt-50">
								{
									(itsVip) ?
										<div>
											<div className="flex_center">VIP Статус</div>
											<div className="flex_center __ModalFontDescription pt-5">Подключено до {MomentEndVip}</div>
										</div>
									: 
										(itsUnlim) ?
											<div>
												<div className="flex_center">Безлимит</div>
												<div className="flex_center __ModalFontDescription pt-5">Подключено до {MomentEndUnlim}</div>
											</div>
										:
											<div>
												<div className="flex_center">{attempts}</div>
												<div className="flex_center">Попыток</div>
											</div>
								}
								
								
							</div>
							<div className="heartbeatBox flex_center pt-50">
								<FontAwesomeIcon className={(heartBeatAnimate) ? `heartbeatIcon heartbeat_start` : `heartbeatIcon`} size="6x" icon={faHeartbeat} />
							</div>
							<div className="pt-10">
								{
									(partnerSayNo && user) ?
										<div>
											<div className="flex_center">{user.first_name} {(partner.sex === 1) ? `приняла` : `принял`} решение</div>
											<div className="flex_center">не общаться с вами</div>
										</div>
									: null
								}
							</div>
							<div className={(partnerSayNo) ? `flex_center pt-15` : `flex_center pt-50`}>
								{
									(twist) ?
										<Button onClick={this.stopTwist}>Остановить поиск</Button>
									: 
										<Button onClick={this.twist}>Крутить рулетку</Button>
								}
							</div>
						</div>
				}
			</Panel>
		)
	}
}

export default Twister;