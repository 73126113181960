import React from 'react';
import { Panel, List, Cell } from '@vkontakte/vkui';
import Header from '../components/Header/Header';

import config from '../config';

import Icon24Notification from '@vkontakte/icons/dist/24/notification';
import Icon24Users from '@vkontakte/icons/dist/24/users';
import Icon24Help from '@vkontakte/icons/dist/24/help';
import Icon24Settings from '@vkontakte/icons/dist/24/settings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCrown } from '@fortawesome/free-solid-svg-icons'

class Settings extends React.Component {
	render() {
		const { id, snackbar, navigator } = this.props
		return (
			<Panel theme="white" id={id}>
				<Header name="Настройки"/>
				{ 
					/* Уведомления */
					snackbar 
				} 
				<List>
					<Cell before={<FontAwesomeIcon className="__Settings_faCrown" size="1x" icon={faCrown} />} expandable onClick={() => { navigator.goPanel('BuyVip')}}>VIP статус</Cell>
					<Cell before={<Icon24Settings fill="var(--standart_color)" />} expandable onClick={() => { navigator.goPanel('Profile')}}>Настройки поиска/анкеты</Cell>
					<Cell before={<Icon24Notification fill="var(--standart_color)" />} expandable onClick={() => { navigator.goPanel('Notify')}}>Уведомления</Cell>
					<Cell href={`https://vk.me/club${config.group_id}`} target="_blank" before={<Icon24Help fill="var(--standart_color)" />} expandable >Сообщить о проблеме</Cell>
					<Cell href={`https://vk.com/club${config.group_id}`} target="_blank" before={<Icon24Users fill="var(--standart_color)" />} expandable >Сообщество приложения</Cell>
				</List>
			</Panel>
		)
	}
}

export default Settings;