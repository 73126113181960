import React from 'react';
import './style.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMars, faVenus } from '@fortawesome/free-solid-svg-icons'

class SexChange extends React.Component {
	constructor(props) {
		super(props);
		const { sex } = this.props
		this.state = {
			sex: +sex || 1
		};
	}

	onClick = (e) => {
		const { onChange } = this.props
		const { target } = e.currentTarget.dataset
		this.setState({
			sex: +target,
		})
		onChange(+target)
	}

	render() {
		const { sex } = this.state
		return (
			<div className="flex_center">
				<div data-target="2" onClick={this.onClick} className={(sex === 2) ? `__MaleFemale_item __Male_target` : `__MaleFemale_item`}>
					<div className="flex_center">
						<FontAwesomeIcon size="4x" icon={faMars} />
					</div>
					<div className="flex_center">Мужчина</div>
				</div>
				<div data-target="1" onClick={this.onClick} className={(sex === 1) ? `__MaleFemale_item __Female_target` : `__MaleFemale_item`}>
					<div className="flex_center">
						<FontAwesomeIcon size="4x" icon={faVenus} />
					</div>
					<div className="flex_center">Женщина</div>
				</div>
			</div>
		)
	}
}

export default SexChange;