import app from './app';
import config from '../config';
import { wssInit } from './wss';
import { getProfile, getSavedDialogs } from './back';
import { getParams, getHash, setLocalStorage, getLocalStorage } from './utils';
import { getMissingUsers, getStartToken, listenVKWebAppUpdateConfig, listenVKWebAppViewRestore, isWebView, getUserInfo, setViewSettings, getMissingCity, getMissingCountry } from './vk';


export function go(callback) {
	//событие смены темы
	listenVKWebAppUpdateConfig((data) => {
		const scheme = data.scheme ? data.scheme : 'bright_light';
		/* Красим статусбар */
		if (scheme === 'client_light' || scheme === 'bright_light') {
			setViewSettings({"status_bar_style": "light", "action_bar_color": "#892E98"})
		}
		
		app.setState({
			scheme: scheme
		})
	})

	//событие возобновления из кеша
	listenVKWebAppViewRestore(() => {
		if (config.bugFix_restoreApp) {
			app.setState({
				isLoad: false, //если false, то приложение перерендерится заново
				wssCloseCode: false,
			}, () => {
				console.log('возобновление из кэша!')
				_start()
			})
		}
	})

	_start()
}

function _start() {
	const params = getParams()
	const hash = getHash()

	getUserInfo((userInfo) => {	
		config.set({
			params: params,
			hash: hash,
			app_id: +params.vk_app_id,
			userInfo: userInfo
		}, () => {
			getStartToken(params.vk_access_token_settings.split(','), (token) => {
				getProfile((response) => { //Получаем профиль, если его нет заставляем создать.
					const { profile } = response
					startApp(token, false, profile)
				})
			})
		})
	})
}

export function startApp(token, isSeeWelcome = false, profile = false) {
	const { needScope } = config
	const userScope = (token) ? token.scope.split(',') : []
	const access_token = (token) ? token.access_token : ""
	
	const need_params = { //дополнительные параметры для функции firstQuery, чтобы получить нужные данные
		access_token: access_token,
		goStartPanel: goStartPanel(userScope, needScope, isSeeWelcome, profile),
		isSeeWelcome: isSeeWelcome,
		profile: profile,
	}

	config.set({
		access_token: need_params.access_token,
	}, () => {

		firstQuery(need_params, (response) => {
			app.setState((state, props) => ({
				profile: (profile) ? profile : false,
				userScope: userScope,
				goStartPanel: need_params.goStartPanel,
				isLoad: true,
				header: (config.params.vk_platform === "desktop_web" && config.desktopHeader) ? false : true,
				isStartInGroup: (need_params.vk_group_id) ? true : isStartInGroup(),
				isAdminInGroup: isAdminInGroup(),
				isWebView: isWebView(),
				...response
			}), () => {
				if (need_params.isSeeWelcome) {
					setLocalStorage('welcome', 'true')
				}
			})
		})

	})
}

function firstQuery(need_params = {}, callback = () => {}) {
	//тут все запросы к беку и все запросы к апи вк
	const { goStartPanel, access_token, profile } = need_params //, access_token - если нужен

	if (!goStartPanel) {
		//получаем данные с бекенда
		//коннектимся к сокету если нужно
		wssInit((data) => {
			const dialogTs = Math.floor(Date.now() / 1000) //текущий таймстамп
			getSavedDialogs(0, 10, dialogTs, (response) => {
				const { profiles, countDialog } = response
				const needUids = profiles.map(({uid}) => { return uid})
				
				let needCity= profiles.map(({city}) => { return (city) ? city : null})
				needCity = needCity.filter((x) => { return (x) })
				
				let needCountry= profiles.map(({country}) => { return (country) ? country : null})
				needCountry = needCountry.filter((x) => { return (x) })

				/* Получаем страну и город из базы ВК по их id */
				const { country, city } = profile
				getMissingCity([], access_token, [city, ...needCity], (apiCitiesData) => {
					getMissingCountry([], access_token, [country, ...needCountry], (apiCountriesData) => {
						/* Получаем аккаунты пользователей */
						getMissingUsers([], access_token, needUids, (info) => {
							callback({ 
								countDialog:countDialog, 
								dialogTs:dialogTs, 
								profiles:profiles, 
								apiUsersData:(info) ? info : [], 
								apiCitiesData: (apiCitiesData) ? apiCitiesData : [],
								apiCountriesData: (apiCountriesData) ? apiCountriesData : [],
							})
						})
					})
				})

			})
		})
	} else {
		//не получаем данные с бекенда
		callback({})
	}
}

export function goStartPanel(userScope, needScope, isSeeWelcome = false, profile = false) {
	if (profile) {
		if (needScope.length > 0) {
			//нужны какие-то права по умолчанию
			if (needScope[0] === "") {
				if (userScope.length > 0) {
					return false
				} else {
					return true
				}
			} else {
				let test = true
				needScope.forEach((sc) => {
					if (userScope.indexOf(sc) === -1) {
						test = false; //нужных прав нет
					}
				})

				if (test) {
					//все права для запуска есть
					return false
				} else {
					//не все права для запуска есть, кидаем на стартовую панель
					return true
				}
			}
		} else {
			//права вовсе не нужны
			if (isSeeWelcome) {
				return false
			} else {
				return isStartPanel()
			}
			
		}
	} else {
		return true
	}

}

function isAdminInGroup() {
	return (config.params.vk_viewer_group_role === 'admin') ? true : false
}

function isStartPanel() {
	//проверить localstorage
	const welcome = getLocalStorage('welcome')
	if (!welcome && config.startPanel) {
		return true
	} else {
		return false
	}
}

function isStartInGroup() {
	return (config.params.vk_group_id) ? true : false
}