import React from 'react';
import { Link } from '@vkontakte/vkui';
import { showErrorMessage } from '../../func/alert';
import './style.css';

class Text extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			show: (this.props.children.length <= this.props.length) ? true : false,
		};
	}

	nToBr = (text, itsVip) => {
		// eslint-disable-next-line
		const AllRegexp = /(\[[a-zA-Z@:\/\/\.0-9-_#=&?]+\|[a-zA-Zа-яА-ЯёЁ@:\/\/\.0-9-_ ]+\])|([@*]{1}[a-zA-Z@:\/\/\.0-9-_#=&?]+[ ]{0,1}\([a-zA-Zа-яА-ЯёЁ@:\/\/\.0-9-_ ]+\))|(<.*?>)|([https]{4,5}:\/\/[a-z0-9-]{2,}\.[a-z]{1}[a-z0-9-]{0,5}[\?]{0,1}[a-zA-Z@:\/\/\.0-9-_#=&?]+)|([w]{3}\.[a-z0-9-]{2,}\.[a-z]{1}[a-z0-9-]{0,5}[\?]{0,1}[a-zA-Z@:\/\/\.0-9-_#=&?]+)|([a-z0-9-]{2,}\.[a-z]{1}[a-z0-9-]{0,5}[\?]{0,1}[a-zA-Z@:\/\/\.0-9-_#=&?]+)|([@*]{1}[a-zA-Z.0-9-_]+ )/gmu //|(((http|https):\/\/)?(www\.)?[a-z0-9-]{2,}\.[a-z]{1}[a-z0-9-]{1,5}([^\s.,()]{1,})?)
		// eslint-disable-next-line
		const oneRegExp = /(\[[a-zA-Z@:\/\/\.0-9-_#=&?]+\|[a-zA-Zа-яА-ЯёЁ@:\/\/\.0-9-_ ]+\])/gmu
		// eslint-disable-next-line
		const twoRegExp = /([@*]{1}[a-zA-Z@:\/\/\.0-9-_#=&?]+[ ]{0,1}\([a-zA-Zа-яА-ЯёЁ@:\/\/\.0-9-_ ]+\))/gmu
		// eslint-disable-next-line
		const treeRegExp = /(<.*?>)/gmu
		// eslint-disable-next-line
		const fourRegExp = /([https]{4,5}:\/\/[a-z0-9-]{2,}\.[a-z]{1}[a-z0-9-]{0,5}[\?]{0,1}[a-zA-Z@:\/\/\.0-9-_#=&?]+)/mu
		// eslint-disable-next-line
		const fiveRegExp = /([w]{3}\.[a-z0-9-]{2,}\.[a-z]{1}[a-z0-9-]{0,5}[\?]{0,1}[a-zA-Z@:\/\/\.0-9-_#=&?]+)/mu
		// eslint-disable-next-line
		const sixRegExp = /([a-z0-9-]{2,}\.[a-z]{1}[a-z0-9-]{0,5}[\?]{0,1}[a-zA-Z@:\/\/\.0-9-_#=&?]+)/mu
		// eslint-disable-next-line
		const sevenRegExp = /([@*]{1}[a-zA-Z.0-9-_]+ )/mu
		// eslint-disable-next-line
		const linkPrefix = /([https]{4,5}:\/\/[a-z0-9-]{2,}\.[a-z]{1}[a-z0-9-]{0,5}[\?]{0,1}[a-zA-Z@:\/\/\.0-9-_#=&?]+)/mu
		// eslint-disable-next-line
		
		

		const arrayText = text.split('\n')
		let result = [];
		arrayText.forEach((t,i) => { 
			let arr = t.split(AllRegexp)
			arr = arr.filter(function(x) {
				return x !== undefined && x !== null; 
			});
			if (i > 0) {
				result.push(<br key={`br-${i}`}/>)
			}
			//тут мы преобразуем элементы массива в ноде элементы
			arr.forEach((elem, k) => {
				if (elem && elem.length > 0) {
					if (oneRegExp.test(elem)) {
						let tag = elem.split('|')
						let href = tag[0].replace('[', '')
						let text = tag[1].replace(']', '')
						//если нет домена, то дописываем vk.com
						if (linkPrefix.test(href)) {
							if (itsVip) {
								result.push(<Link href={href} target="_blank" rel="noopener noreferrer" key={`linkOne-${i}-${k}`}>{text}</Link>)
							} else {
								result.push(<Link className="_Text_link" onClick={() => { showErrorMessage("Внимание!", 'Для просмотра ссылок рекомендуем подключить VIP статус.') }} target="_blank" rel="noopener noreferrer" key={`linkOne-${i}-${k}`}>ссылка заблокирована</Link>)
							}
						} else {
							if (itsVip) {
								result.push(<Link href={`//vk.com/${href}`} target="_blank" rel="noopener noreferrer" key={`linkOne-${i}-${k}`}>{text}</Link>)
							} else {
								result.push(<Link className="_Text_link" onClick={() => { showErrorMessage("Внимание!", 'Для просмотра ссылок рекомендуем подключить VIP статус.') }} target="_blank" rel="noopener noreferrer" key={`linkOne-${i}-${k}`}>ссылка заблокирована</Link>)
							}
						}
					} else if (twoRegExp.test(elem)) {
						let tag = elem.split('(')
						let href = tag[0].replace('@', '').replace('*', '').trim()
						let text = tag[1].replace(')', '')
						
						if (fourRegExp.test(href)) {
							if (itsVip) {
								result.push(<Link href={href} target="_blank" rel="noopener noreferrer" key={`linkTwo-${i}-${k}`}>{text}</Link>)
							} else {
								result.push(<Link className="_Text_link" onClick={() => { showErrorMessage("Внимание!", 'Для просмотра ссылок рекомендуем подключить VIP статус.') }} target="_blank" rel="noopener noreferrer" key={`linkTwo-${i}-${k}`}>ссылка заблокирована</Link>)
							}
						} else {
							if (fiveRegExp.test(href)) {
								if (itsVip) {
									result.push(<Link href={`https://${href}`} target="_blank" rel="noopener noreferrer" key={`linkTwo-${i}-${k}`}>{text}</Link>)
								} else {
									result.push(<Link className="_Text_link" onClick={() => { showErrorMessage("Внимание!", 'Для просмотра ссылок рекомендуем подключить VIP статус.') }} target="_blank" rel="noopener noreferrer" key={`linkTwo-${i}-${k}`}>ссылка заблокирована</Link>)
								}
							} else {
								if (sixRegExp.test(href)) {
									if (itsVip) {
										result.push(<Link href={`https://${href}`} target="_blank" rel="noopener noreferrer" key={`linkTwo-${i}-${k}`}>{text}</Link>)
									} else {
										result.push(<Link className="_Text_link" onClick={() => { showErrorMessage("Внимание!", 'Для просмотра ссылок рекомендуем подключить VIP статус.') }} target="_blank" rel="noopener noreferrer" key={`linkTwo-${i}-${k}`}>ссылка заблокирована</Link>)
									}
								} else {
									if (itsVip) {
										result.push(<Link href={`https://vk.com/${href}`} target="_blank" rel="noopener noreferrer" key={`linkTwo-${i}-${k}`}>{text}</Link>)
									} else {
										result.push(<Link className="_Text_link" onClick={() => { showErrorMessage("Внимание!", 'Для просмотра ссылок рекомендуем подключить VIP статус.') }} target="_blank" rel="noopener noreferrer" key={`linkTwo-${i}-${k}`}>ссылка заблокирована</Link>)
									}
								}
							}
						}
					} else if (treeRegExp.test(elem)) {
						result.push(<strong key={`strong-${i}-${k}`}>{elem.substr(1, elem.length-2)}</strong>)
					} else if (fourRegExp.test(elem)) {
						if (itsVip) {
							result.push(<Link href={`${elem}`} target="_blank" rel="noopener noreferrer" key={`linkFour-${i}-${k}`}>{elem}</Link>)
						} else {
							result.push(<Link className="_Text_link" onClick={() => { showErrorMessage("Внимание!", 'Для просмотра ссылок рекомендуем подключить VIP статус.') }} target="_blank" rel="noopener noreferrer" key={`linkFour-${i}-${k}`}>ссылка заблокирована</Link>)
						}
					} else if (fiveRegExp.test(elem)) {
						if (itsVip) {
							result.push(<Link href={`//${elem}`} target="_blank" rel="noopener noreferrer" key={`linkFour-${i}-${k}`}>{elem}</Link>)
						} else {
							result.push(<Link className="_Text_link" onClick={() => { showErrorMessage("Внимание!", 'Для просмотра ссылок рекомендуем подключить VIP статус.') }} target="_blank" rel="noopener noreferrer" key={`linkFour-${i}-${k}`}>ссылка заблокирована</Link>)
						}
					} else if (sixRegExp.test(elem)) {
						if (itsVip) {
							result.push(<Link href={`//${elem}`} target="_blank" rel="noopener noreferrer" key={`linkFour-${i}-${k}`}>{elem}</Link>)
						} else {
							result.push(<Link className="_Text_link" onClick={() => { showErrorMessage("Внимание!", 'Для просмотра ссылок рекомендуем подключить VIP статус.') }} target="_blank" rel="noopener noreferrer" key={`linkFour-${i}-${k}`}>ссылка заблокирована</Link>)
						}
					} else if (sevenRegExp.test(elem)) {
						if (itsVip) {
							result.push(<Link href={`https://vk.com/${elem.replace('@', '').replace('*', '').trim()}`} target="_blank" rel="noopener noreferrer" key={`linkFour-${i}-${k}`}>{elem}</Link>)
						} else {
							result.push(<Link className="_Text_link" onClick={() => { showErrorMessage("Внимание!", 'Для просмотра ссылок рекомендуем подключить VIP статус.') }} target="_blank" rel="noopener noreferrer" key={`linkFour-${i}-${k}`}>ссылка заблокирована</Link>)
						}
					} else {
						result.push(elem)
					}
				}
			})
		})
		
		return result
	}


	getSmallText = (text, length) => {
		let { itsVip } = this.props
		text = `${text.substr(0,length).trim()}...`
		return ( this.nToBr(text, itsVip) )
	}

	showAllText = () => {
		this.setState({
			show: true,
		})
	}

	render() {
		let { children, length, showMoreButton, className, itsVip } = this.props
		let { show } = this.state
		return (
			(show) ?
				<div className={className}>{this.nToBr(children, itsVip)}</div>
			:
				<div className={className}>
					<div>{this.getSmallText(children, length)}</div>
					{
						(showMoreButton) ?
							<div className="__textBlockShowAll">
								<Link className="_tb_item" onClick={this.showAllText}>Показать полностью...</Link>
							</div>
						: null
					}
				</div>
		)
	}
}

export default Text;